import { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import Spinner from "../components/shared/Spinner";
import Errors from "../components/shared/Errors";
import PageTitle from "components/PageTitle";
import { Button } from "components/shared/base";
import { FiArrowDownCircle } from "react-icons/fi";
import { Alert } from "components/shared/Toast";
import http from "utils/http";

const FETCH_ALL_CATALOGS = gql`
   query FETCH_ALL_CATALOGS {
      allCatalogs(statusIn: ["new", "old"]) {
         id
         name
         poster: posterSrc
         pdf: pdfSrc
         status
      }
   }
`;

function CatalogPage() {
   const { loading, error, data } = useQuery(FETCH_ALL_CATALOGS);
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const newCatalogs = data.allCatalogs.filter(i => i.status === "NEW");
   const oldCatelogs = data.allCatalogs.filter(i => i.status === "OLD");

   return (
      <div>
         <PageTitle
            title="2025 New Product Catalog"
            subtitle="FUN WITHOUT LIMITS"
         />

         <div className="px-10 md:px-24 xl:px-36 container mx-auto">
            <div className="grid grid-cols-2 gap-16 my-16">
               {newCatalogs.map(cat => (
                  <Catalog cat={cat} key={cat.id} />
               ))}
            </div>
         </div>

         <hr />

         <div className="container mx-auto px-10">
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-16 my-16">
               {oldCatelogs.map(cat => (
                  <Catalog cat={cat} key={cat.id} />
               ))}
            </div>
         </div>
      </div>
   );
}

const Catalog = ({ cat }) => {
   const [loading, setLoading] = useState(false);

   async function download(name, src) {
      try {
         setLoading(true);
         const res = await http.get(src, { responseType: "blob" });
         console.log("res", res);
         const url = window.URL.createObjectURL(new Blob([res.data]));
         var link = document.createElement("a");
         link.href = url;
         link.download = `${name}.pdf`;
         link.dispatchEvent(new MouseEvent("click"));
         setLoading(false);
      } catch (error) {
         Alert("error", error.mesesage);
         setLoading(false);
      }
   }

   return (
      <div className="flex flex-col space-y-4">
         <div className="_df_button bg-none p-0" source={cat.pdf}>
            <img className="flex-1" src={cat.poster} alt="Catalog cover" />
         </div>

         <h3 className="text-center">{cat.name}</h3>
         <div className="flex items-center justify-center mt-8 text-base space-x-8">
            <Button
               loading={loading}
               disabled={loading}
               onClick={() => download(cat.name, cat.pdf)}
               title="Download"
               rightIcon={<FiArrowDownCircle className="ml-2" />}
            />
         </div>
      </div>
   );
};

export default CatalogPage;
